{
  "fontSize": {
    "9Xl": "128px",
    "8Xl": "96px",
    "7Xl": "72px",
    "6Xl": "60px",
    "5Xl": "48px",
    "4Xl": "36px",
    "3Xl": "30px",
    "3XlBold": "30px",
    "2Xl": "24px",
    "2XlMedium": "24px",
    "xl": "20px",
    "xlMedium": "20px",
    "lg": "18px",
    "lgItalic": "18px",
    "lgMedium": "18px",
    "lgMediumItalic": "18px",
    "base": "16px",
    "baseMedium": "16px",
    "sm": "14px",
    "smMedium": "14px",
    "xs": "12px",
    "xsMedium": "12px"
  },
  "textDecoration": {
    "9Xl": "none",
    "8Xl": "none",
    "7Xl": "none",
    "6Xl": "none",
    "5Xl": "none",
    "4Xl": "none",
    "3Xl": "none",
    "3XlBold": "none",
    "2Xl": "none",
    "2XlMedium": "none",
    "xl": "none",
    "xlMedium": "none",
    "lg": "none",
    "lgItalic": "none",
    "lgMedium": "none",
    "lgMediumItalic": "none",
    "base": "none",
    "baseMedium": "none",
    "sm": "none",
    "smMedium": "none",
    "xs": "none",
    "xsMedium": "none"
  },
  "fontFamily": {
    "9Xl": "Helvetica Now Display",
    "8Xl": "Helvetica Now Display",
    "7Xl": "Helvetica Now Display",
    "6Xl": "Helvetica Now Display",
    "5Xl": "Helvetica Now Display",
    "4Xl": "Helvetica Now Display",
    "3Xl": "Helvetica Now Display",
    "3XlBold": "Helvetica Now Display",
    "2Xl": "Helvetica Now Display",
    "2XlMedium": "Helvetica Now Display",
    "xl": "Helvetica Now Text",
    "xlMedium": "Helvetica Now Text",
    "lg": "Helvetica Now Text",
    "lgItalic": "Helvetica Now Text",
    "lgMedium": "Helvetica Now Text",
    "lgMediumItalic": "Helvetica Now Text",
    "base": "Helvetica Now Text",
    "baseMedium": "Helvetica Now Text",
    "sm": "Helvetica Now Text",
    "smMedium": "Helvetica Now Text",
    "xs": "Helvetica Now Text",
    "xsMedium": "Helvetica Now Text"
  },
  "fontWeight": {
    "9Xl": 500,
    "8Xl": 500,
    "7Xl": 500,
    "6Xl": 500,
    "5Xl": 500,
    "4Xl": 500,
    "3Xl": 500,
    "3XlBold": 700,
    "2Xl": 400,
    "2XlMedium": 500,
    "xl": 400,
    "xlMedium": 500,
    "lg": 400,
    "lgItalic": 400,
    "lgMedium": 500,
    "lgMediumItalic": 500,
    "base": 400,
    "baseMedium": 500,
    "sm": 400,
    "smMedium": 500,
    "xs": 400,
    "xsMedium": 500
  },
  "fontStyle": {
    "9Xl": "normal",
    "8Xl": "normal",
    "7Xl": "normal",
    "6Xl": "normal",
    "5Xl": "normal",
    "4Xl": "normal",
    "3Xl": "normal",
    "3XlBold": "normal",
    "2Xl": "normal",
    "2XlMedium": "normal",
    "xl": "normal",
    "xlMedium": "normal",
    "lg": "normal",
    "lgItalic": "italic",
    "lgMedium": "normal",
    "lgMediumItalic": "italic",
    "base": "normal",
    "baseMedium": "normal",
    "sm": "normal",
    "smMedium": "normal",
    "xs": "normal",
    "xsMedium": "normal"
  },
  "fontStretch": {
    "9Xl": "normal",
    "8Xl": "normal",
    "7Xl": "normal",
    "6Xl": "normal",
    "5Xl": "normal",
    "4Xl": "normal",
    "3Xl": "normal",
    "3XlBold": "normal",
    "2Xl": "normal",
    "2XlMedium": "normal",
    "xl": "normal",
    "xlMedium": "normal",
    "lg": "normal",
    "lgItalic": "normal",
    "lgMedium": "normal",
    "lgMediumItalic": "normal",
    "base": "normal",
    "baseMedium": "normal",
    "sm": "normal",
    "smMedium": "normal",
    "xs": "normal",
    "xsMedium": "normal"
  },
  "letterSpacing": {
    "9Xl": "-0.04em",
    "8Xl": "-0.02em",
    "7Xl": "-0.01em",
    "6Xl": "0em",
    "5Xl": "0em",
    "4Xl": "0em",
    "3Xl": "0em",
    "3XlBold": "0em",
    "2Xl": "0em",
    "2XlMedium": "0em",
    "xl": "0em",
    "xlMedium": "0em",
    "lg": "0em",
    "lgItalic": "0em",
    "lgMedium": "0em",
    "lgMediumItalic": "0em",
    "base": "0em",
    "baseMedium": "0em",
    "sm": "0em",
    "smMedium": "0em",
    "xs": "0em",
    "xsMedium": "0em"
  },
  "lineHeight": {
    "9Xl": "128px",
    "8Xl": "96px",
    "7Xl": "72px",
    "6Xl": "60px",
    "5Xl": "48px",
    "4Xl": "45px",
    "3Xl": "37.5px",
    "3XlBold": "37.5px",
    "2Xl": "34.8px",
    "2XlMedium": "34.8px",
    "xl": "32px",
    "xlMedium": "32px",
    "lg": "32px",
    "lgItalic": "32px",
    "lgMedium": "32px",
    "lgMediumItalic": "32px",
    "base": "24px",
    "baseMedium": "24px",
    "sm": "18px",
    "smMedium": "18px",
    "xs": "16px",
    "xsMedium": "16px"
  },
  "paragraphIndent": {
    "9Xl": "0px",
    "8Xl": "0px",
    "7Xl": "0px",
    "6Xl": "0px",
    "5Xl": "0px",
    "4Xl": "0px",
    "3Xl": "0px",
    "3XlBold": "0px",
    "2Xl": "0px",
    "2XlMedium": "0px",
    "xl": "0px",
    "xlMedium": "0px",
    "lg": "0px",
    "lgItalic": "0px",
    "lgMedium": "0px",
    "lgMediumItalic": "0px",
    "base": "0px",
    "baseMedium": "0px",
    "sm": "0px",
    "smMedium": "0px",
    "xs": "0px",
    "xsMedium": "0px"
  },
  "paragraphSpacing": {
    "9Xl": "0px",
    "8Xl": "0px",
    "7Xl": "0px",
    "6Xl": "0px",
    "5Xl": "0px",
    "4Xl": "0px",
    "3Xl": "0px",
    "3XlBold": "0px",
    "2Xl": "0px",
    "2XlMedium": "0px",
    "xl": "0px",
    "xlMedium": "0px",
    "lg": "0px",
    "lgItalic": "0px",
    "lgMedium": "0px",
    "lgMediumItalic": "0px",
    "base": "0px",
    "baseMedium": "0px",
    "sm": "0px",
    "smMedium": "0px",
    "xs": "0px",
    "xsMedium": "0px"
  },
  "textCase": {
    "9Xl": "none",
    "8Xl": "none",
    "7Xl": "none",
    "6Xl": "none",
    "5Xl": "none",
    "4Xl": "none",
    "3Xl": "none",
    "3XlBold": "none",
    "2Xl": "none",
    "2XlMedium": "none",
    "xl": "none",
    "xlMedium": "none",
    "lg": "none",
    "lgItalic": "none",
    "lgMedium": "none",
    "lgMediumItalic": "none",
    "base": "none",
    "baseMedium": "none",
    "sm": "none",
    "smMedium": "none",
    "xs": "none",
    "xsMedium": "none"
  }
}