{
  "primary": {
    "50": "#f7f8ffff",
    "100": "#d1d3ffff",
    "200": "#abadffff",
    "300": "#8485ffff",
    "400": "#5f5effff",
    "500": "#3c37ffff",
    "600": "#322ddfff",
    "700": "#2824bfff",
    "800": "#1f1c9fff",
    "900": "#171580ff"
  },
  "light": {
    "on-background": {
      "50": "#ffffffff",
      "100": "#fafafaff",
      "200": "#f2f2f2ff",
      "300": "#e6e6e6ff",
      "400": "#d9d9d9ff",
      "500": "#7c7c7cff",
      "600": "#626262ff",
      "700": "#474747ff",
      "800": "#2d2d2dff",
      "900": "#131313ff"
    },
    "background": {
      "50": "#131313ff",
      "100": "#2d2d2dff",
      "200": "#474747ff",
      "300": "#626262ff",
      "400": "#7c7c7cff",
      "500": "#d9d9d9ff",
      "600": "#e6e6e6ff",
      "700": "#f2f2f2ff",
      "800": "#fafafaff",
      "900": "#ffffffff"
    }
  },
  "gray": {
    "50": "#f9fafbff",
    "100": "#f3f4f6ff",
    "200": "#e5e7ebff",
    "300": "#d1d5dbff",
    "400": "#9ca3afff",
    "500": "#6b7280ff",
    "600": "#4b5563ff",
    "700": "#374151ff",
    "800": "#1f2937ff",
    "900": "#111827ff"
  },
  "dark": {
    "background": {
      "50": "#ffffffff",
      "100": "#e6e7e6ff",
      "200": "#ccceceff",
      "300": "#b3b6b5ff",
      "400": "#9a9d9cff",
      "500": "#676c6bff",
      "600": "#4e5352ff",
      "700": "#343b3aff",
      "800": "#343b3aff",
      "900": "#1b2221ff"
    },
    "on-background": {
      "50": "#1a1f21ff",
      "100": "#343b3aff",
      "200": "#343b3aff",
      "300": "#4e5352ff",
      "400": "#676c6bff",
      "500": "#9a9d9cff",
      "600": "#b3b6b5ff",
      "700": "#ccceceff",
      "800": "#e6e7e6ff",
      "900": "#ffffffff"
    }
  }
}